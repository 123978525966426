<template>
  <floating-menu
    v-if="editor"
    pluginKey="floatingMenuAI"
    :editor="editor"
    :tippyOptions="tippyOptions"
    :tippyPos="tippyPos"
    :shouldShow="shouldShow()"
  >
    <div id="floating-menu-action-item">
      <div class="text-lg lg:text-base pl-1">
        <button
          v-show="showMention"
          @click="editor.chain().focus().addMention().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon-svg"
            width="17"
            height="17"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="4" />
            <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
          </svg>
        </button>

        <button
          v-show="showDatetag"
          @click="editor.chain().focus().addDatetag().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon-svg"
            width="17"
            height="17"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="4" y="5" width="16" height="16" rx="2" />
            <line x1="16" y1="3" x2="16" y2="7" />
            <line x1="8" y1="3" x2="8" y2="7" />
            <line x1="4" y1="11" x2="20" y2="11" />
            <line x1="10" y1="16" x2="14" y2="16" />
            <line x1="12" y1="14" x2="12" y2="18" />
          </svg>
        </button>
      </div>
    </div>
  </floating-menu>
</template>

<script>
import { FloatingMenu } from './FloatingMenu';
import { posToDOMRect } from '@tiptap/core';
import { imagePluginKey } from '../Nodes/Image';

export default {
  components: { FloatingMenu },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    tippyOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showMention: true,
      showDatetag: true,
    };
  },
  methods: {
    shouldShow() {
      return ({ state }) => {
        const { selection } = state;
        const { $anchor, empty } = selection;
        if (!empty) return false;

        const imageDecos = imagePluginKey.getState(state);
        if (imageDecos.children.length > 0 || imageDecos.local.length > 0)
          return false;

        const actionItemParentMaybe = $anchor.node(-1);
        let isActiveActionItem =
          actionItemParentMaybe?.type.name == 'action_item';
        this.showDatetag = !actionItemParentMaybe?.attrs?.dueDate;
        this.showMention = !actionItemParentMaybe?.attrs?.owner;
        if (isActiveActionItem && (this.showMention || this.showDatetag)) {
          return true;
        }

        return false;
      };
    },
    tippyPos(view, from, to) {
      const { state } = view;
      const { $anchor } = state.selection;
      const actionItemParentMaybe = $anchor.node(-1);

      let posFrom = from;
      let posTo = to;
      if (actionItemParentMaybe.type.name == 'action_item') {
        // before(-1) return absolute position of AI + the content size == end of AI
        posFrom = $anchor.before(-1) + actionItemParentMaybe.nodeSize - 2;
        posTo = posFrom;
      }

      return () => posToDOMRect(view, posFrom, posTo);
    },
  },
};
</script>

<style>
#floating-menu-action-item button {
  outline: none;
}
#floating-menu-action-item .icon-svg {
  stroke: #d2d1d1;
  display: inline-block;
  margin-top: -5px;
}
.dark #floating-menu-action-item .icon-svg {
  stroke: #6c6c6c;
}
</style>
