// this is needed for snipped editor schema satisfaction as snippet editor does not have actions
import { Node, mergeAttributes } from '@tiptap/core';

const ActionItemDummy = Node.create({
  name: 'action_item_dummy',

  group: 'actionblock',
  content: 'paragraph',
  draggable: true,
  selectable: true,
  defining: true,

  addAttributes() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(
        {
          'data-type': this.name,
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      0,
    ];
  },
});

export default ActionItemDummy;
