import { posToDOMRect } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import tippy from 'tippy.js';
import { imagePluginKey } from '../Nodes/Image';
export class FloatingMenuView {
  shouldShow = ({ state }) => {
    const { selection } = state;
    const { $anchor, empty } = selection;
    const isRootDepth = $anchor.depth === 1;
    const isEmptyTextBlock =
      $anchor.parent.isTextblock &&
      !$anchor.parent.type.spec.code &&
      !$anchor.parent.textContent;

    if (!empty || !isRootDepth || !isEmptyTextBlock) {
      return false;
    }

    return true;
  };

  tippyPos = (view, from, to) => {
    return () => posToDOMRect(view, from, to);
  };

  constructor({
    editor,
    element,
    view,
    tippyOptions = {},
    shouldShow,
    tippyPos,
  }) {
    this.editor = editor;
    this.element = element;
    this.view = view;

    if (shouldShow) {
      this.shouldShow = shouldShow;
    }
    if (tippyPos) {
      this.tippyPos = tippyPos;
    }

    this.element.addEventListener('mousedown', this.mousedownHandler, {
      capture: true,
    });
    this.editor.on('focus', this.focusHandler);
    this.editor.on('blur', this.blurHandler);
    this.tippyOptions = tippyOptions;
    // Detaches menu content from its current parent
    this.element.remove();
    this.element.style.visibility = 'visible';
  }

  mousedownHandler = () => {
    this.preventHide = true;
  };

  focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
    setTimeout(() => this.update(this.editor.view));
  };

  blurHandler = ({ event }) => {
    if (this.preventHide) {
      this.preventHide = false;

      return;
    }

    if (
      event?.relatedTarget &&
      this.element.parentNode?.contains(event.relatedTarget)
    ) {
      return;
    }

    this.hide();
  };

  createTooltip() {
    const { element: editorElement } = this.editor.options;
    const editorIsAttached = !!editorElement.parentElement;

    if (this.tippy || !editorIsAttached) {
      return;
    }

    this.tippy = tippy(editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: 'manual',
      placement: 'right',
      hideOnClick: 'toggle',
      ...this.tippyOptions,
    });
  }

  update(view, oldState) {
    const { state } = view;
    const { doc, selection } = state;
    const { from, to } = selection;
    const imageDecos = imagePluginKey.getState(state);
    const imageDecosLast = oldState
      ? imagePluginKey.getState(oldState)
      : { children: [], local: [] };

    const isSame =
      oldState &&
      oldState.doc.eq(doc) &&
      oldState.selection.eq(selection) &&
      imageDecos.children.length == imageDecosLast.children.length &&
      imageDecos.local.length == imageDecosLast.local.length;

    if (isSame) {
      return;
    }

    this.createTooltip();

    const shouldShow = this.shouldShow?.({
      editor: this.editor,
      view,
      state,
      oldState,
    });

    if (!shouldShow) {
      this.hide();
      return;
    }

    this.tippy?.setProps({
      getReferenceClientRect: this.tippyPos(view, from, to),
    });

    this.show();
  }

  show() {
    this.tippy?.show();
  }

  hide() {
    this.tippy?.hide();
  }

  destroy() {
    this.tippy?.destroy();
    this.element.removeEventListener('mousedown', this.mousedownHandler, {
      capture: true,
    });
    this.editor.off('focus', this.focusHandler);
    this.editor.off('blur', this.blurHandler);
  }
}

export const FloatingMenuPlugin = (options) => {
  return new Plugin({
    key:
      typeof options.pluginKey === 'string'
        ? new PluginKey(options.pluginKey)
        : options.pluginKey,
    view: (view) => new FloatingMenuView({ view, ...options }),
  });
};
