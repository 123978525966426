<template>
  <div>
    <!-- <empty-line-menu :editor="editor" :tippyOptions="tippyOptions" /> -->
    <action-item-menu :editor="editor" :tippyOptions="tippyOptions" />
  </div>
</template>

<script>
import ActionItemMenu from './FloatingMenuAI.vue';
// import EmptyLineMenu from './FloatingMenuEmptyLine.vue';

export default {
  components: { ActionItemMenu },
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tippyOptions: {
        arrow: false,
        zIndex: 8,
        theme: 'meetric-floatingmenu',
        offset: [0, 1],
      },
    };
  },
};
</script>

<style>
.tippy-box[data-theme~='meetric-floatingmenu'] {
  all: unset;
}
.tippy-box[data-theme~='meetric-floatingmenu'] .tippy-content {
  background-color: transparent;
  padding: 0;
  text-align: left;
}
</style>
