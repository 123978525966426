<template>
  <div
    class="editor w-full h-full"
    v-if="editor"
    @click.self="editor.commands.focus('end')"
  >
    <div v-if="editable" class="items-center">
      <m-input
        class="text-lg lg:text-base text-grey3 placeholder-grey3 font-medium"
        :class="
          error && !snippetName
            ? 'border border-base2 pl-1'
            : 'border border-transparent'
        "
        id="name-input"
        :placeholder="error ? 'Please enter template name' : 'Template name'"
        v-model="snippetName"
      />
    </div>

    <div v-if="editable" class="flex bg-white dark:bg-base5 mb-0">
      <editor-menu-bar :editor="editor" :eventID="uuid" :inSnippet="true">
      </editor-menu-bar>
    </div>

    <editor-content
      data-recording-sensitive
      :editor="editor"
      class="prose prose-sm py-3"
      id="meetriceditor"
    />
  </div>
</template>

<script>
import EditorMenuBar from '../Editor2/Menu/EditorMenuBar';
import EditorExtensions from '../Editor2/extensions';
import { Editor, EditorContent } from '@tiptap/vue-2';
import ActionItemDummy from '../Editor2/Nodes/ActionItemDummy.js';
import MInput from '@/components/UI/MInput';

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    MInput,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    uuid: {
      type: String,
      required: true,
    },
    getNote: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      editor: null,
      snippetName: '',
      error: false,
    };
  },
  watch: {
    getNote: function () {
      if (this.snippetName == '') {
        this.error = true;
        return;
      } else this.error = false;

      const payload = {
        name: this.snippetName,
        uuid: this.uuid,
        content: this.editor
          .getHTML()
          .replace(/ data-recording-sensitive=""/g, '')
          .replace(/<br><\/p>/g, '</p>'),
      };
      this.$emit('noteHTML', payload);
    },
  },
  mounted() {
    this.snippetName = this.name;
    this.initEditor();
  },
  methods: {
    initEditor() {
      // disable action_item, reminder,datetag, mention
      const disabled = ['reminder', 'datetag', 'mention'];
      const ext = EditorExtensions().filter((e) => !disabled.includes(e.name));

      this.editor = new Editor({
        content: this.content,
        editable: this.editable,
        emptyEditorText: 'Custom template text...',
        extensions: [...ext, ActionItemDummy],
      });
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
<style scoped>
#name-input {
  @apply text-grey2 placeholder-grey4;
}
</style>
