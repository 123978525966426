<template>
  <div class="quick-start-menu flex flex-col">
    <div class="menu-item flex" v-if="lastAgenda !== false">
      <div class="item-icon">
        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          stroke-linejoin="round"
          stroke-linecap="round"
          fill="none"
          stroke-width="2"
          class="stroke-current"
        >
          <g>
            <ellipse ry="8.66667" rx="8.66667" id="svg_5" cy="12" cx="12" />
          </g>
        </svg>
      </div>

      <div class="item-label" @click="onClick(itemType.LAST_AGENDA)">
        Agenda items from last meeting
      </div>
    </div>
    <div class="menu-item flex" v-if="lastFull !== false">
      <div class="item-icon">
        <svg
          class="stroke-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="5" y="3" width="14" height="18" rx="2" />
          <line x1="9" y1="7" x2="15" y2="7" />
          <line x1="9" y1="11" x2="15" y2="11" />
          <line x1="9" y1="15" x2="13" y2="15" />
        </svg>
      </div>

      <div class="item-label" @click="onClick(itemType.LAST_FULL)">
        Full notes from last meeting
      </div>
    </div>
    <div class="menu-item flex" v-if="calendarDesc !== false">
      <div class="item-icon">
        <svg
          class="stroke-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M17.788 5.108a9 9 0 1 0 3.212 6.892h-8" />
        </svg>
      </div>

      <div class="item-label" @click="onClick(itemType.CALENDAR_DESC)">
        Description from calendar event
      </div>
    </div>
    <div class="menu-item flex">
      <div class="item-icon">
        <svg
          class="stroke-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="16" height="4" rx="1" />
          <rect x="4" y="12" width="6" height="8" rx="1" />
          <line x1="14" y1="12" x2="20" y2="12" />
          <line x1="14" y1="16" x2="20" y2="16" />
          <line x1="14" y1="20" x2="20" y2="20" />
        </svg>
      </div>
      <div class="item-label" @click="onClick(itemType.BRAINSTORMING)">
        Brainstorming meeting agenda template
      </div>
    </div>
    <div class="menu-item flex">
      <div class="item-icon">
        <svg
          class="stroke-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="16" height="4" rx="1" />
          <rect x="4" y="12" width="6" height="8" rx="1" />
          <line x1="14" y1="12" x2="20" y2="12" />
          <line x1="14" y1="16" x2="20" y2="16" />
          <line x1="14" y1="20" x2="20" y2="20" />
        </svg>
      </div>
      <div class="item-label" @click="onClick(itemType.STANDUP)">
        Daily standup agenda template
      </div>
    </div>
    <div class="menu-item" :class="allDisplayed ? 'hidden lg:flex' : 'flex'">
      <div class="item-icon">
        <svg
          class="stroke-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="16" height="4" rx="1" />
          <rect x="4" y="12" width="6" height="8" rx="1" />
          <line x1="14" y1="12" x2="20" y2="12" />
          <line x1="14" y1="16" x2="20" y2="16" />
          <line x1="14" y1="20" x2="20" y2="20" />
        </svg>
      </div>
      <div class="item-label" @click="onClick(itemType.ONE_ON_ONE)">
        1:1 meeting agenda template
      </div>
    </div>

    <div class="">
      Show
      <span class="item-label" @click="onClick(itemType.SHOW_MORE)">more</span>
    </div>
  </div>
</template>

<script>
export const ItemType = Object.freeze({
  LAST_AGENDA: 'last_agenda',
  LAST_FULL: 'last_full',
  CALENDAR_DESC: 'calendar_desc',
  BRAINSTORMING: 'brainstorming',
  STANDUP: 'standup',
  ONE_ON_ONE: 'one_on_one',
  SHOW_MORE: 'showmore',
});
export default {
  components: {},
  props: {
    calendarDesc: {
      type: Boolean,
      required: false,
      default: null,
    },
    lastAgenda: {
      type: Boolean,
      required: false,
      default: null,
    },
    lastFull: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      itemType: ItemType,
    };
  },
  computed: {
    isMobileScreen() {
      return this.$store?.getters['isMobile'];
    },
    allDisplayed() {
      return this.calendarDesc && this.lastAgenda && this.lastFull;
    },
  },
  methods: {
    onClick(type) {
      this.$emit('insert', { type });
      const props = {
        type,
      };
      window.meetric.track('Quickstart note', props);
    },
  },
};
</script>

<style scoped>
.quick-start-menu {
  @apply text-grey4 text-lg lg:text-base dark:text-opacity-40;
}
.menu-item {
  @apply mb-4;
}
.menu-item .item-icon {
  @apply w-6 pt-0.5 lg:pt-0;
}
.item-label {
  @apply underline cursor-pointer opacity-100 hover:opacity-75;
}

.placeholder-wrapper {
  @apply flex flex-grow text-base;
  line-height: 1.5;
}
.placeholder-wrapper::before {
  content: '\200b';
}
.placeholder {
  @apply flex flex-grow rounded-lg my-auto;
  height: 1rem;
}
</style>
