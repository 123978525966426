<template>
  <div>
    <div
      class="flex w-full bg-white dark:bg-base5 pt-1 lg:pt-0 -ml-2 lg:ml-0 mt-2 mb-1"
    >
      <div class="flex">
        <div
          class="flex transition-opacity duration-500 ml-2 lg:ml-0 w-full lg:w-auto"
        >
          <button
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('agenda_item') }"
            @click="toggleWrapSpecialItem({ command: 'toggleAgendaItem' })"
            title="New agenda item - ( )"
          >
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              stroke-linejoin="round"
              stroke-linecap="round"
              fill="none"
              stroke-width="2"
              class="editorbutton stroke-current -ml-1"
            >
              <g>
                <ellipse ry="8.66667" rx="8.66667" id="svg_5" cy="12" cx="12" />
                <ellipse
                  fill="#000000"
                  ry="4.5"
                  rx="4.5"
                  id="svg_6"
                  cy="12"
                  cx="12"
                />
              </g>
            </svg>
          </button>

          <button
            v-if="!inSnippet"
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('action_item') }"
            @click="toggleWrapSpecialItem({ command: 'toggleActionItem' })"
            title="New action item - [ ]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="editorbutton stroke-current -ml-1"
              viewBox="0 0 24 24"
              stroke-width="2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="4" y="4" width="16" height="16" rx="2" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </button>

          <button
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('bold') }"
            @click="editor.chain().toggleBold().focus().run()"
            title="Bold - Ctrl+B or ⌘+B"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              :class="inSnippet ? '-ml-1' : ''"
              viewBox="0 0 24 24"
              class="editorbutton fill-current"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"
                />
              </g>
            </svg>
          </button>

          <button
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('italic') }"
            @click="editor.chain().toggleItalic().focus().run()"
            title="Italic - Ctrl+I or ⌘+I"
          >
            <svg
              class="editorbutton fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z"
                />
              </g>
            </svg>
          </button>

          <button
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('underline') }"
            @click="editor.chain().toggleUnderline().focus().run()"
            title="Underline - Ctrl+U or ⌘+U"
          >
            <svg
              class="editorbutton fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M8 3v9a4 4 0 1 0 8 0V3h2v9a6 6 0 1 1-12 0V3h2zM4 20h16v2H4v-2z"
                />
              </g>
            </svg>
          </button>

          <button
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('strike') }"
            @click="editor.chain().toggleStrike().focus().run()"
            title="Strikethrough"
          >
            <svg
              class="editorbutton fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"
                />
              </g>
            </svg>
          </button>

          <button
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            @click="editor.chain().toggleHeading({ level: 1 }).focus().run()"
            title="Heading 1"
          >
            <svg
              class="editorbutton fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g>
                <path fill="none" d="M0 0H24V24H0z" />
                <path
                  d="M13 20h-2v-7H4v7H2V4h2v7h7V4h2v16zm8-12v12h-2v-9.796l-2 .536V8.67L19.5 8H21z"
                />
              </g>
            </svg>
          </button>

          <button
            type="button"
            class="formattingbutton"
            :class="{
              'is-active':
                editor.isActive('heading', { level: 2 }) ||
                editor.isActive('heading', { level: 3 }),
            }"
            @click="
              editor.isActive('heading', { level: 3 })
                ? editor.chain().toggleHeading({ level: 3 }).focus().run()
                : editor.chain().toggleHeading({ level: 2 }).focus().run()
            "
            title="Heading 2"
          >
            <svg
              class="editorbutton fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g>
                <path fill="none" d="M0 0H24V24H0z" />
                <path
                  d="M4 4v7h7V4h2v16h-2v-7H4v7H2V4h2zm14.5 4c2.071 0 3.75 1.679 3.75 3.75 0 .857-.288 1.648-.772 2.28l-.148.18L18.034 18H22v2h-7v-1.556l4.82-5.546c.268-.307.43-.709.43-1.148 0-.966-.784-1.75-1.75-1.75-.918 0-1.671.707-1.744 1.606l-.006.144h-2C14.75 9.679 16.429 8 18.5 8z"
                />
              </g>
            </svg>
          </button>

          <button
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('bulletList') }"
            @click="editor.chain().focus().toggleBulletList().run()"
            title="Bullet list"
          >
            <svg
              class="editorbutton fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                />
              </g>
            </svg>
          </button>

          <button
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('orderedList') }"
            @click="editor.chain().focus().toggleOrderedList().run()"
            title="Numbered list"
          >
            <svg
              class="editorbutton fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                />
              </g>
            </svg>
          </button>

          <button
            type="button"
            class="formattingbutton"
            :class="{ 'is-active': editor.isActive('link') }"
            @click="setUrl()"
            title="Hyperlink - Ctrl+K or ⌘+K"
          >
            <svg
              viewBox="0 0 24 24"
              class="editorbutton fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"
                />
              </g>
            </svg>
          </button>
        </div>
        <slot></slot>

        <!-- link handling -->
        <div
          :id="'link-bubble-' + eventID"
          class="z-40 mt-2"
          v-show="linkMenuIsActive"
        >
          <form @submit.prevent.stop="setLinkUrl(linkUrl)">
            <m-text-modal>
              <input
                class="py-1 px-3 text-grey2 placeholder-grey3"
                type="text"
                v-model="linkUrl"
                placeholder="Type or paste link here"
                ref="linkInput"
                @keydown.esc="hideLinkMenu"
                @blur="hideLinkMenu"
              />
            </m-text-modal>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MTextModal from '@/components/UI/MTextModal';

export default {
  components: {
    MTextModal,
  },
  props: {
    editor: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    eventID: {
      type: String,
      required: true,
    },
    inSnippet: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      linkPopup: null,
      linkUrl: null,
      linkMenuIsActive: false,
    };
  },
  mounted() {
    document.addEventListener('keydown', this.linkShortcut);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.linkShortcut);
  },
  methods: {
    toggleWrapSpecialItem({ command }) {
      // commands: toggleAgendaItem or toggleActionItem as name of the function
      const can = this.editor.can().chain().focus()[command]().run();

      let valid = this.editor.chain().focus();

      // if we can't insert action item
      if (!can) valid.createParagraphNear();

      // toggle the one we want
      valid = valid[command]().run();

      if (!valid) {
        const text = 'Oops! Couldn’t create this item there';
        this.$snack(text);
        const props = {
          errorMsg:
            name == 'action_item'
              ? 'actioncreateerror'
              : 'agendaitemcreateerror',
          text: text,
          isPrivate: this.editor?.options?.isPrivate,
        };
        window.meetric.track('Error', props);
      }
    },
    linkShortcut(e) {
      if (
        !((e.keyCode === 75 && e.ctrlKey) || (e.keyCode === 75 && e.metaKey))
      ) {
        return;
      }
      if (this.editor.view.hasFocus()) {
        e.preventDefault();
        this.setUrl();
      }
    },
    showLinkMenu(href = '') {
      this.linkUrl = href;
      this.linkMenuIsActive = true;
      this.$emit('linkmenuopen');
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
      this.$emit('linkmenuclosed');
    },
    setLinkUrl(url) {
      if (url == '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
      } else {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run();
      }

      this.hideLinkMenu();
    },
    setUrl() {
      const state = this.editor.state;

      const { from, to } = state.selection;
      const coords = this.editor.view.coordsAtPos(from);

      let menu = document.getElementById('link-bubble-' + this.eventID);
      // to get correct position as the menu is part of EditorMenu component and not doc
      // so 0,0 is in the EditorMenu, not screen but we need screen coords
      let coords_par = menu.parentElement.getBoundingClientRect();
      menu.style.position = 'absolute';
      menu.style.left = coords.left - coords_par.left + 'px';
      menu.style.top = coords.top - coords_par.top + 20 + 'px';

      let marks = [];
      let hasImage = false;
      state.doc.nodesBetween(from, to, (node) => {
        marks = [...marks, ...node.marks];
        if (node.type.name == 'image') hasImage = true;
      });

      if (hasImage) {
        this.$emit('imageinlink', { type: 'image' });
        return false;
      }

      const mark = marks.find((markItem) => markItem.type.name === 'link');

      if (mark && mark.type.name == 'link') {
        this.showLinkMenu(mark.attrs.href);
      } else {
        if (state.selection.empty) {
          return false;
        }
        this.showLinkMenu();
      }
    },
  },
};
</script>

<style>
.textmodal .no-results {
  @apply px-3 py-1;
}

.formattingbutton {
  @apply outline-none focus:outline-none flex-grow;
}
.formattingbutton.is-active svg {
  @apply opacity-50 bg-grey5;
}
/* Highlight on formatting icons */
/* .dark .formattingbutton.is-active svg {
  @apply bg-grey1;
} */

.dark .formattingbutton svg {
  color: bg-grey6;
}

.editorbutton {
  @apply h-6 inline rounded mr-2 my-auto;
  padding: 0.3rem;
  opacity: 0.35;
}
.editorbutton:hover {
  @apply opacity-50;
}

.dark .editorbutton {
  @apply opacity-50;
}
.dark .editorbutton:hover {
  @apply opacity-80;
}
.formattingbutton.is-active svg {
  @apply opacity-80 bg-grey6;
}
</style>
<style scoped>
.dark .ping {
  display: none;
}
</style>
