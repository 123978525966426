import { FloatingMenuPlugin } from '../Plugins/FloatingMenu';

export const FloatingMenu = {
  name: 'FloatingMenu',

  props: {
    pluginKey: {
      type: [String, Object],
      default: 'floatingMenu',
    },

    editor: {
      type: Object,
      required: true,
    },

    tippyOptions: {
      type: Object,
      default: () => ({}),
    },

    shouldShow: {
      type: Function,
      default: null,
    },
    tippyPos: {
      type: Function,
      default: null,
    },
  },

  // data() {
  //   return {
  //     menu: {
  //       isActive: false,
  //       ai: {
  //         isActive: false,
  //         dueDate: false,
  //         owner: false,
  //       },
  //       empty: {
  //         isActive: false,
  //       },
  //     },
  //   };
  // },

  watch: {
    editor: {
      immediate: true,
      handler(editor) {
        if (!editor) {
          return;
        }

        this.$nextTick(() => {
          editor.registerPlugin(
            FloatingMenuPlugin({
              pluginKey: this.pluginKey,
              editor,
              element: this.$el,
              tippyOptions: this.tippyOptions,
              shouldShow: this.shouldShow,
              tippyPos: this.tippyPos,
            })
          );
        });
      },
    },
  },

  render(createElement) {
    return createElement(
      'div',
      { style: { visibility: 'hidden' } },
      this.$slots.default
    );
  },

  beforeDestroy() {
    this.editor.unregisterPlugin(this.pluginKey);
  },
};
